<ion-app>
  <ion-tabs>
    <ion-tab tab="home">
      <ion-header translucent="true">
        <ion-toolbar>
          <ion-title>
            <ion-skeleton-text animated style="width: 20%" />
          </ion-title>
          <ion-item lines="none" slot="end">
            <ion-label>
              <ion-skeleton-text animated style="width: 20%" />
            </ion-label>
            <ion-select ok-text="Switch" cancel-text="Nevermind">
              <ion-select-option value="I-84">
                <ion-skeleton-text animated style="width: 20%" />
              </ion-select-option>
            </ion-select>
            <ion-skeleton-text animated style="width: 20%" />
          </ion-item>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-header>
              <ion-card-subtitle
                ><ion-skeleton-text
                  animated
                  style="width: 80%"
                /></ion-card-subtitle
              >
              <ion-card-title
                ><ion-skeleton-text
                  animated
                  style="width: 10%"
                /></ion-card-title
              >
            </ion-card-header>
            <ion-card-content>
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 50%" />
              <ion-skeleton-text animated style="width: 70%" />
            </ion-card-content>
          </ion-card>
        </ion-list>
      </ion-content>
    </ion-tab>

    <ion-tab tab="incidents" />

    <ion-tab tab="settings" />

    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="home">
        <ion-label>Home</ion-label>
        <ion-icon src="/icons/home-outline.svg" />
      </ion-tab-button>
      <ion-tab-button tab="incidents">
        <ion-label>Incidents</ion-label>
        <ion-icon src="/icons/warning-outline.svg" />
      </ion-tab-button>
      <ion-tab-button tab="settings">
        <ion-label>Settings</ion-label>
        <ion-icon name="settings-outline" />
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-app>
