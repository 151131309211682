<script>
  import { appData, currentTab, highwayList, isLoading } from "../stores"

  import Header from "./Header.svelte"

  import ReportCard from "./ReportCard.svelte"

  $: sortedReports = $appData.reports.sort(
    (a, b) => b.start_milepost - a.start_milepost
  )

  // console.log(sortedReports)
  // console.log("appData: ", $appData)
  // console.log("currentTab: ", $currentTab)
  // console.log("isLoading: ", $isLoading)
</script>

<Header />
<ion-content>
  <ion-list>
    {#each sortedReports as report}
      <ReportCard {report} />
    {:else}
      <div class="no-report">
        <h2>No <span>live</span> reports to show...</h2>
        <p>
          There are {$appData.incidents.length} incidents.
        </p>
        <p>
          There are {$appData.cams.length} cameras.
        </p>
      </div>
    {/each}
  </ion-list>
</ion-content>

<style>
  .no-report {
    padding: 2rem;
  }
  .no-report h2 {
    margin-bottom: 2rem;
  }
  .no-report span {
    font-style: italic;
    font-weight: bold;
    color: rgb(6, 6, 48);
  }
</style>
