<script>
  import {
    highwayList,
    currentHighway,
    isLoading,
    currentTab,
    appData,
  } from "../stores"

  import { db } from "../components/helpers/db.svelte"

  import { onSnapshot, doc } from "firebase/firestore"

  let highways = Array.from($highwayList)
  highways.sort()

  const selectChange = async (event) => {
    isLoading.set(true)
    currentHighway.set(event.detail.value)
    const unsubscribe = onSnapshot(
      doc(db, `oregon`, `${$currentHighway}`),
      (doc) => {
        if (doc.exists()) {
          console.log("DATA CHANGED ", doc.data())
          try {
            appData.set({
              reports: doc.data().reports
                ? Object.values(doc.data().reports)
                : [],
              cams: doc.data().cams ? Object.values(doc.data().cams) : [],
              incidents: doc.data().incidents
                ? Object.values(doc.data().incidents)
                : [],
            })
          } catch (error) {
            console.log(error)
          }
        } else {
          $appData = {
            reports: [],
            cams: [],
            incidents: [],
          }
        }
      }
    )
    isLoading.set(false)
  }
</script>

<svelte:head>
  <title
    >Interstate Pro | {$currentHighway.replace("I", "I-")} | {$currentTab}</title
  >
</svelte:head>

<ion-header translucent="true">
  <ion-toolbar>
    <ion-item slot="start" lines="none"
      >{$currentHighway.replace("I", "I-")}</ion-item
    >
    <ion-item slot="end" lines="none">
      <ion-label>Switch Route</ion-label>
      <ion-select
        on:ionChange={selectChange}
        ok-text="Switch"
        cancel-text="Nevermind"
      >
        {#each highways as highway}
          <ion-select-option
            value={highway}
            disabled={highway === $currentHighway}
            >{highway.replace("I", "I-")}</ion-select-option
          >
        {/each}
      </ion-select>
    </ion-item>
  </ion-toolbar>
</ion-header>
