<script>
  import { onMount } from "svelte"
  import { slide } from "svelte/transition"
  import { fetchForecast } from "../weather"

  export let lat
  export let lon
  export let name
  export let showExtendedWeather
  export let toggleExtendedWeather

  let forecasts = []
  let loading = true

  onMount(async () => {
    const data = await fetchForecast(lat, lon)
    forecasts = data
    loading = false
  })
</script>

{#if loading}
  <ion-spinner name="bubbles" />
{:else}
  <ion-list transition:slide={{
    delay: 250,
    duration: 300,
  }}>
    <ion-item lines="none">
      <ion-label>Show Extended Forecast</ion-label>
      <ion-toggle
        slot="end"
        checked={showExtendedWeather}
        on:ionChange={toggleExtendedWeather}
      />
    </ion-item>
    {#if !showExtendedWeather}
      <ion-item lines="none">
        <ion-thumbnail slot="start">
          <!-- svelte-ignore a11y-missing-attribute -->
          <img src={forecasts[0].icon} />
        </ion-thumbnail>
        <ion-label>
          <!-- svelte-ignore missing-declaration -->
          <h3>{forecasts[0].name}</h3>
          <p class="ion-text-wrap">{forecasts[0].detailedForecast}</p>
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-thumbnail slot="start">
          <!-- svelte-ignore a11y-missing-attribute -->
          <img src={forecasts[1].icon} />
        </ion-thumbnail>
        <ion-label>
          <!-- svelte-ignore missing-declaration -->
          <h3>{forecasts[1].name}</h3>
          <p class="ion-text-wrap">{forecasts[1].detailedForecast}</p>
        </ion-label>
      </ion-item>
    {/if}

    {#if showExtendedWeather}
      {#each forecasts as forecast}
        <ion-item lines="none">
          <ion-thumbnail slot="start">
            <!-- svelte-ignore a11y-missing-attribute -->
            <img src={forecast.icon} />
          </ion-thumbnail>
          <ion-label>
            <!-- svelte-ignore missing-declaration -->
            <h3>
              {forecast.name}, {moment(forecast.startTime).format("MMMM Do")}
            </h3>
            <p class="ion-text-wrap">{forecast.detailedForecast}</p>
          </ion-label>
        </ion-item>
      {:else}
        <ion-chip color="secondary">
          <ion-label color="dark"
            >Error fetching the forecast for {name}</ion-label
          >
        </ion-chip>
      {/each}
    {/if}
  </ion-list>
{/if}
