const axios = require("axios").default

axios.defaults.headers = {
  Accept: "application/ld+json",
}

const getWxMeta = async (lat, lon) => {
  const wxMeta = {}
  try {
    const { data } = await axios.get(
      `https://api.weather.gov/points/${lat},${lon}`
    )
    wxMeta.gridId = data.gridId
    wxMeta.gridX = data.gridX
    wxMeta.gridY = data.gridY
    wxMeta.gridY = data.gridY
    wxMeta.radarStation = data.radarStation
    wxMeta.forecastLink = data.forecast
    wxMeta.forecastHourlyLink = data.forecastHourly
    wxMeta.forecastGridDataLink = data.forecastGridData
    wxMeta.observationStationsLink = data.observationStations
    wxMeta.forecastOfficeLink = data.forecastOffice
    wxMeta.forecastZoneLink = data.forecastZone
    wxMeta.fireWeatherZoneLink = data.fireWeatherZone
    // console.log(wxMeta)
    return wxMeta
  } catch (error) {
    console.log(error)
    return new Error(
      `Something went wrong fetching metadata for position at ${lat}, ${lon}`,
      error
    )
  }
}

const fetchForecast = async (lat, lon) => {
  const link = await getWxMeta(lat, lon)
  const { data } = await axios.get(link.forecastLink)
  return data.periods
}

exports.fetchForecast = fetchForecast
