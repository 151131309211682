<script>
  import { onMount, onDestroy } from "svelte"
  import {
    currentHighway,
    appData,
    highwayList,
    isLoading,
    currentTab,
  } from "./stores"
  import HomeTab from "./components/HomeTab.svelte"
  import CamerasTab from "./components/CamerasTab.svelte"
  import IncidentsTab from "./components/IncidentsTab.svelte"
  import Skeleton from "./components/Skeleton.svelte"
  import AdSection from "./components/AdSection.svelte"

  import { db } from "./components/helpers/db.svelte"

  import { getDoc, doc, onSnapshot } from "firebase/firestore"

  let docRef, docSnap, unsubscribe

  onMount(async () => {
    $isLoading = true

    unsubscribe = onSnapshot(doc(db, `oregon`, `${$currentHighway}`), (doc) => {
      console.log("DATA CHANGED ", doc.data())
      try {
        appData.set({
          reports: doc.data().reports ? Object.values(doc.data().reports) : [],
          cams: doc.data().cams ? Object.values(doc.data().cams) : [],
          incidents: doc.data().incidents
            ? Object.values(doc.data().incidents)
            : [],
        })
      } catch (error) {
        console.log(error)
      }
    })

    docRef = doc(db, "oregon/routes")
    docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const routes = Array.from(Object.values(docSnap.data()))
      $highwayList = routes
    }

    isLoading.set(false)
  })

  onDestroy(() => {
    unsubscribe()
  })
</script>

)

{#if $isLoading}
  <Skeleton />
{:else}
  <ion-app>
    <ion-tabs>
      <ion-tab tab="home">
        <HomeTab />
        <AdSection />
      </ion-tab>

      <ion-tab tab="incidents">
        <IncidentsTab />
        <AdSection />
      </ion-tab>

      <ion-tab tab="cameras">
        <CamerasTab />
        <AdSection />
      </ion-tab>

      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="home" on:click={() => currentTab.set("Home")}>
          <ion-label>Home</ion-label>
          <ion-icon src="/icons/home-outline.svg" />
        </ion-tab-button>

        <ion-tab-button
          tab="incidents"
          on:click={() => currentTab.set("Incidents")}
        >
          <ion-label
            >Incidents (<span class="length"
              >{$appData.incidents.length
                ? $appData.incidents.length
                : "0"}</span
            >)</ion-label
          >
          <ion-icon src="/icons/warning-outline.svg" />
        </ion-tab-button>

        <ion-tab-button
          tab="cameras"
          on:click={() => currentTab.set("Cameras")}
        >
          <ion-label
            >Cameras (<span class="length">{$appData.cams.length}</span
            >)</ion-label
          >
          <ion-icon src="/icons/videocam-outline.svg" />
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-app>
{/if}

<style>
  .length {
    color: red;
  }
  /* ion-tab-bar {  */
</style>
