<script>
    import AdItem from "./AdItem.svelte";
</script>

<!-- <ion-item> -->
    <div class="ad-section">
        <div class="support_our_sponsors">
            Support Our Sponsors!
        </div>
        <AdItem />
    </div>
<!-- </ion-item> -->

<style>
    .ad-section {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        /* padding: .9rem; */
        width: 95%;
        height: 8vh;
        border: rgb(122, 122, 122) solid 1px;
        /* border-radius: 1rem; */
        margin: auto;
        
    }
    .support_our_sponsors {
        position: absolute;
        left: .75rem;
        top: -1.1rem;
        background-color: rgb(255, 255, 255);
        padding: 2px .25rem;
        border-radius: 1rem;
    }
</style>
