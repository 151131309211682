<script>
  import Header from "./Header.svelte";
  import { appData } from "../stores";
</script>

<Header />
<ion-content>
  <ion-list>
    {#each $appData.cams as cam}
      <ion-img
        class="cam_image"
        src={cam.url.replace("http://", "https://")}
        alt={cam.other}
      />
    {:else}
      <p>No Cameras to display</p>
    {/each}
  </ion-list>
</ion-content>
