<script context="module">
  import { initializeApp } from "firebase/app"
  import { getFirestore } from "firebase/firestore"
  const firebaseApp = initializeApp({
    apiKey: "AIzaSyBQngqyStbLCyAJYV3W1Cj4wcMDSHBAJLQ",
    authDomain: "interstate-pro-c5dc4.firebaseapp.com",
    projectId: "interstate-pro-c5dc4",
  })

  export const db = getFirestore(firebaseApp)
</script>
