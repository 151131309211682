<script>
  import { slide } from "svelte/transition"
  import Weather from "./Weather.svelte"
  export let report
  // console.log(report)

  const lat = ((report.start_lat + report.end_lat) / 2).toFixed(4)
  const lon = ((report.start_lon + report.end_lon) / 2).toFixed(4)

  import { appData } from "../stores"
  import {
    getRestrictionDescription,
    getRoadConditions,
    getWeatherConditions,
  } from "../utils"

  let showCams = false
  const toggleCams = () => {
    showCams = !showCams
  }
  let showWeather = false
  const toggleWeather = () => {
    showWeather = !showWeather
  }
  let showExtendedWeather = false
  const toggleExtendedWeather = () => {
    showExtendedWeather = !showExtendedWeather
  }

  const cams = $appData.cams.filter(
    (cam) =>
      (Math.ceil(cam.milepoint) >= Math.floor(report.start_milepost) &&
        Math.floor(cam.milepoint) <= Math.ceil(report.end_milepost)) ||
      (Math.floor(cam.milepoint) <= Math.ceil(report.start_milepost) &&
        Math.ceil(cam.milepoint) >= Math.floor(report.end_milepost))
  )
  const hasCams = cams.length > 0
</script>

<ion-item-divider sticky="true" class="ion-no-margin"
  ><ion-label>{report.name}</ion-label></ion-item-divider
>
<ion-card>
  <ion-card-header>
    <ion-card-subtitle class="ion-text-uppercase">
      milepost {report.start_milepost} to {report.end_milepost}
    </ion-card-subtitle>
    {#if report.driving_restriction_id === 1}
      <p class="alert_background">
        {getRestrictionDescription(report.driving_restriction_id)}<br />
        {report.comments}
      </p>
    {/if}
    {#if report.driving_restriction_id === "A"}
      <p class="info_background">
        {getRestrictionDescription(report.driving_restriction_id)}<br />
      </p>
    {:else if report.driving_restriction_id === "B" || report.driving_restriction_id === "B1" || report.driving_restriction_id === "C"}
      <p class="alert_background">
        {getRestrictionDescription(report.driving_restriction_id)}<br />
      </p>
    {:else if report.driving_restriction_id === "D"}
      <p class="alert_background">
        {getRestrictionDescription(report.driving_restriction_id)}<br />
        {report.comments}
      </p>
    {/if}
    <ion-card-title>
      {Math.floor(report.air_temperature * 1.8 + 32)}&deg;
    </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <p>
      {getWeatherConditions(report.weather_cond_id)}
    </p>
    <p>{getRoadConditions(report.road_cond_id)}</p>
    {#if report.commercial_restriction_id !== 0}
      <p class="info_background">
        {report.commercial_restriction_desc}
      </p>
    {/if}
    <!-- svelte-ignore missing-declaration -->
    <p>
      <!-- Updated: {moment(report.updated).format("ddd, MMM Do YYYY, hh:mm a")} -->
      Updated {moment(report.updated).fromNow()}
    </p>
    <ion-list>
      {#if hasCams}
        <ion-item lines="none">
          <ion-label
            >Show Cameras<ion-chip>
              <ion-label color="secondary">{cams.length}</ion-label>
            </ion-chip></ion-label
          >

          <!-- <ion-badge>{cams.length}</ion-badge> -->
          <ion-toggle slot="end" checked={showCams} on:ionChange={toggleCams} />
        </ion-item>
        {#if showCams}
          {#each cams as cam}
            <ion-img
              transition:slide={{
                delay: 250,
                duration: 300,
              }}
              class="cam_image"
              src={cam.url.replace("http://", "https://")}
              alt={report.name}
            />
          {/each}
        {/if}
      {:else}
        <ion-item lines="none">
          <ion-label>No Cameras Available</ion-label>
          <ion-toggle slot="end" disabled />
        </ion-item>
      {/if}
    </ion-list>
    <ion-item lines="none">
      <ion-label>Show Weather</ion-label>
      <ion-toggle
        slot="end"
        checked={showWeather}
        on:ionChange={toggleWeather}
      />
    </ion-item>
    {#if showWeather}
      <Weather
        {lat}
        {lon}
        name={report.name}
        {showExtendedWeather}
        {toggleExtendedWeather}
      />
    {/if}
  </ion-card-content>
</ion-card>

<style>
  .alert_background {
    background-color: rgb(247, 120, 120);
    color: #ffffff;
    font-weight: bold;
    padding: 0.3rem;
  }
  .info_background {
    background-color: rgb(255, 205, 97);
    color: #000000;
    font-weight: bold;
    padding: 0.3rem;
  }
  .cam_image {
    margin-bottom: 0.5rem;
  }
</style>
