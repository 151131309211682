<script>
  import Header from "./Header.svelte"
  import { appData } from "../stores"
</script>

<Header />
<ion-toolbar> <ion-title>Incidents</ion-title></ion-toolbar>
<ion-content>
  {#each $appData.incidents as incd}
    <ion-card>
      <ion-card-header>
        <ion-card-title>
          Milepost {Math.round(incd.start_milepost)}
          {#if Math.round(incd.end_milepost) && Math.round(incd.end_milepost) !== Math.round(incd.start_milepost)}
            to {Math.round(incd.end_milepost)}
          {/if}
        </ion-card-title>
        <ion-card-subtitle>
          {incd.headline}
        </ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <p>{incd.impact_desc}</p>
      </ion-card-content></ion-card
    >
  {:else}
    <p>No incidents to display</p>
  {/each}
</ion-content>
