export const getRestrictionDescription = (code) => {
  let response
  switch (code) {
    case "0":
      response = ""
      break
    case "A":
      response = "Carry Chains or Traction Tires"
      break
    case "B":
      response = "Chains Required on vehicles towing or over 10,000 GVW"
      break
    case "B1":
      response =
        "Chains Required on vehicles towing or single drive axle over 10,000 GVW"
      break
    case "C":
      response =
        "Chains Required. Traction tires allowed in place of chains on vehicles under 10,000 GVW and not towing. Vehicles towing must use chains."
      break
    case "D":
      response =
        "Chains Required. Traction tires alone are not sufficient. See additional details."
      break

    default:
      response = ""
      break
  }
  return response
}
export const getWeatherConditions = (id) => {
  let desc
  switch (id) {
    case 1:
      desc = "Clear / No Precip"
      break
    case 2:
      desc = "Partly Cloudy"
      break
    case 3:
      desc = "Overcast"
      break
    case 4:
      desc = "Ground Fog"
      break
    case 5:
      desc = "Intermittent Showers"
      break
    case 6:
      desc = "Rain"
      break
    case 7:
      desc = "Snow Flurries"
      break
    case 8:
      desc = "Snow Hard/Continuous"
      break
    case 9:
      desc = "Severe Weather Alert"
      break
    case 91:
      desc = "SWA-Freezing Rain"
      break
    case 92:
      desc = "SWA-High Winds"
      break
    case 93:
      desc = "SWA-Whiteout"
      break
    case 94:
      desc = "SWA-Dust Storm"
      break
    case 95:
      desc = "SWA-Falling Trees"
      break

    default:
      desc = ""
      break
  }
  return desc
}

export const getRoadConditions = (id) => {
  let desc

  switch (id) {
    case 0:
      desc = "No Report"
      break

    case 1:
      desc = "Bare Pavement"
      break

    case 2:
      desc = "Spots of Ice"
      break

    case 3:
      desc = "Black Ice"
      break

    case 4:
      desc = "Standing Water / Flooding"
      break

    case 5:
      desc = "Snow Pack Breaking Up"
      break

    case 6:
      desc = "Packed Snow"
      break

    default:
      desc = ""
      break
  }
  return desc
}
